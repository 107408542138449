import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import RIDE_CALC from './ride_calculations.json'
import RIDE_AGE from './ride_age.json'
import styled from 'styled-components'
var RideStatsContainer = styled.div`
  width: 30vw;
  min-width: 439px;
  & > label {
    display: block;
    text-align: left;
    margin: 2px;
    padding: 6px 10px;
    position: relative;
    user-select: none;
  }
  & > label > input[type="text"] {
    width: 228px;
  }
  & > label:nth-child(5-6) {
    cursor: pointer;
  }
  & > label > input, select {
    float: right;
    padding: 6px 10px;
    box-sizing: border-box;
  }
  & > label > input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  & > label:hover input ~ span {
    background-color: #ccc;
  }

  & > label > input:checked ~ span {
    background-color: #2196F3;
  }

  & > label > input:checked ~ span:after {
    display: block;
  }


  & > label > span:after {
    right: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid #e0e0e0;
`
var Checkmark = styled.span`
  position: absolute;
  top: 10px;
  right: 212px;
  height: 25px;
  width: 25px;
  cursor: pointer;
  background-color: #eee;
  &::after {
    content: "";
    position: absolute;
    display: none;

  }
`
var Table = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 0;
  padding: 0;
  & > div {
    width: 33%;
  }
  & h3 {
    font-size: 14px;
    margin: 0;
  }
  width: 800px;
  text-align: center;
`
var Cell = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
  padding: 0.4em .6em;
  overflow: hidden;
  list-style: none;
  border: solid 3px white;
  background: rgba(112, 128, 144, 0.2);
`
var Header = styled.div`
  background: linear-gradient(90deg,#4c86e5,#6aa4eb);
  width: 100%;
  height: 75px;
  & h1 {
    color: white;
    margin: 0;
    line-height: 75px;

  }

`
var MainContainer = styled.div`
  width: 70vw;
  margin: 0 auto;
  padding-top: 20px;
  background: white;
  border: 1px solid #e0e0e0;
  min-height: calc(100% - 100px);
  position: absolute;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
`
var Main = styled.div`
  width: 60vw;
  margin: 0 auto;
  background: white;
  h2 {
    text-align: left;
    margin: 2em 0 0 0;
  }
`
var Button = styled.div`
  width: 100px;
  height: 30px;
  line-height: 30px;
  color: white;
  background: linear-gradient(90deg,#4c86e5,#6aa4eb);
  user-select: none;
  display: inline-block;
  margin: 0 20px;
  text-align: center;
  cursor: pointer;
`
var RideTableContainer = styled.div`
  text-align: left;
  & > h2 {
    display: inline-block;
  }
  margin-bottom: 30px;
`
var BottomText = styled.p`
  font-style: italic;
  margin: 0;
  font-size: 12px;

`
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRide: '0',
      rideBaseValue: 0,
      excitement: "",
      intensity: "",
      nausea: "",
      sameRide: "",
      entryCharge: "",
      saved: []
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveRide = this.saveRide.bind(this)
    this.deleteTable = this.deleteTable.bind(this)
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  componentDidUpdate(prevProps, prevState) {

    //re-calculate prices
    if (prevState.selectedRide !== this.state.selectedRide
    || prevState.excitement !== this.state.excitement
    || prevState.intensity !== this.state.intensity
    || prevState.nausea !== this.state.nausea
    || prevState.sameRide !== this.state.sameRide
    || prevState.entryCharge !== this.state.entryCharge) {
      console.log(typeof this.state.excitement)
      var selectedRide = RIDE_CALC[this.state.selectedRide]
      var excitement = Math.floor(((parseFloat(this.state.excitement) || 0) * 3200 * selectedRide["M Excitement"])/32768)
      var intensity = Math.floor(((parseFloat(this.state.intensity) || 0) * 3200 * selectedRide["M Intensity"])/32768)
      var nausea = Math.floor(((parseFloat(this.state.nausea) || 0) * 3200 * selectedRide["M Nausea"])/32768)
      console.log(this.state.sameRide)
      var total = excitement + intensity + nausea
      if (this.state.sameRide) {
        total -= Math.floor(total/4)
      }
      if (this.state.entryCharge) {
        total = Math.floor(total/4)
      }
      this.setState({
        rideBaseValue: total
      })
    }
  }

  constructTable(rideBaseValue) {
    var cells = [];
    cells.push(<Cell style={{order: 1}}><h3>Ride Age (Months)</h3></Cell>)
    RIDE_AGE.forEach((val, index) => {
      var order = (index % RIDE_AGE.length) + 2
      cells.push(<Cell style={{order}}>{val.age}</Cell>)
    })
    // cells.push(<Cell style={{order: 1}}><h3>Ride Multiplier</h3></Cell>)
    // RIDE_AGE.forEach((val, index) => {
    //   var order = (index % RIDE_AGE.length) + 2
    //   cells.push(<Cell style={{order}}>{val.multiplier}</Cell>)
    // })
    // cells.push(<Cell style={{order: 1}}><h3>Aged Ride Value</h3></Cell>)
    // RIDE_AGE.forEach((val, index) => {
    //   var order = (index % RIDE_AGE.length) + 2
    //   cells.push(<Cell style={{order}}>{Math.floor(rideBaseValue * val.multiplier)}</Cell>)
    // })
    // cells.push(<Cell style={{order: 1}}><h3>Max price for "Good Value"</h3></Cell>)
    // RIDE_AGE.forEach((val, index) => {
    //   var order = (index % RIDE_AGE.length) + 2
    //   var agedRideVal = Math.floor(rideBaseValue * val.multiplier);
    //   cells.push(<Cell style={{order}}>${(Math.floor(agedRideVal/2)/10).toFixed(2)}</Cell>)
    // })
    cells.push(<Cell style={{order: 1}}><h3>Max OpenRCT2 price</h3></Cell>)
    RIDE_AGE.forEach((val, index) => {
      var order = (index % RIDE_AGE.length) + 2
      var agedRideVal = Math.floor(rideBaseValue * val.multiplier);
      var cost = Math.max((Math.floor(agedRideVal/.5)/10), 0).toFixed(2)
      cells.push(<Cell style={{order}}>${cost}</Cell>)
    })
    cells.push(<Cell style={{order: 1}}><h3>Max RCT1/RCT2/RCTC price</h3></Cell>)
    RIDE_AGE.forEach((val, index) => {
      var order = (index % RIDE_AGE.length) + 2


      if (index >= 2) {
        var agedRideVal = Math.floor(rideBaseValue * val.multiplier);
      } else if (index == 1) {
        var agedRideVal = rideBaseValue + 10
      } else {
        var agedRideVal = rideBaseValue + 30
      }
      var cost = Math.max((Math.floor(agedRideVal/.5)/10), 0).toFixed(2)
      cells.push(<Cell style={{order}}>${cost}</Cell>)
    })
    return cells;
  }
  saveRide() {
    var number = 1;
    for (var rideDetails of this.state.saved) {
      if (rideDetails.selectedRide === this.state.selectedRide) {
        number++;
      }
    }

    var rideDetails = {
      selectedRide: this.state.selectedRide,
      rideBaseValue: this.state.rideBaseValue,
      excitement: this.state.excitement,
      intensity: this.state.intensity,
      nausea: this.state.nausea,
      sameRide: this.state.sameRide,
      entryCharge: this.state.entryCharge,
      name: RIDE_CALC[this.state.selectedRide]["Ride type"] + " " + number
    }
    var saved = this.state.saved
    saved.push(rideDetails)
    this.setState({
      saved
    })

  }
  deleteTable(e) {

    const name = e.target.getAttribute("name");
    var saved = this.state.saved;
    console.log(e.target)
    console.log(saved)
    for (var i = 0; i < saved.length; i++) {
      if (saved[i].name === name) {
        saved.splice(i, 1);
        this.setState({
          saved
        })
        break;
      }
    }
  }
  render() {
    var options = [];
    RIDE_CALC.forEach((ride, index) => {
        options.push(<option value={index}>{ride["Ride type"]}</option>)
    })
    var cells = this.constructTable(this.state.rideBaseValue);

    var savedRideTables = [];
    for (var savedDetails of this.state.saved) {

      savedRideTables.push(
        <RideTableContainer key={savedDetails.name}>
          <h2 >{savedDetails.name}</h2>
          <Button name={savedDetails.name} onClick={this.deleteTable}>Delete</Button>
          <Table>
            {this.constructTable(savedDetails.rideBaseValue)}
          </Table>
        </RideTableContainer>
      )

    }
    return (
      <div className="App">
        <Header>
          <h1>RCT2 Ride Price Calculator</h1>
        </Header>
        <MainContainer>
        <Main>
        <p>This is a calculator for how much you can charge on rides, based on their statistics.
        Just enter your ride's stats below and it will update the table. You can also save multiple rides on this
        page by clicking the save box above the table.</p>
          <RideStatsContainer>
            <label>
              Ride type:
              <select value={this.state.value} name="selectedRide" onChange={this.handleInputChange}>
                {options}
              </select>
            </label>
            <label>
              Excitement:
              <input type="text" name="excitement" value={this.state.excitement} onChange={this.handleInputChange} />
            </label>
            <label>
              Intensity:
              <input type="text" name="intensity" value={this.state.intensity} onChange={this.handleInputChange} />
            </label>
            <label>
              Nausea:
              <input type="text" name="nausea" value={this.state.nausea} onChange={this.handleInputChange} />
            </label>
            <label>
              Same type in park?
              <input
                name="sameRide"
                type="checkbox"
                checked={this.state.sameRide}
                onChange={this.handleInputChange} />
                <Checkmark />
            </label>
            <label>
              Charge entry for park?
              <input
                name="entryCharge"
                type="checkbox"
                checked={this.state.entryCharge}
                onChange={this.handleInputChange} />
                <Checkmark />
            </label>
          </RideStatsContainer>
          <RideTableContainer>
            <h2>{RIDE_CALC[this.state.selectedRide]["Ride type"]}</h2>
            <Button onClick={this.saveRide}>Save</Button>

            <Table>
              {cells}
            </Table>
          </RideTableContainer>
          {savedRideTables}
          <BottomText>Made for <a href="https://www.youtube.com/channel/UCBlXovStrlQkVA2xJEROUNg">Marcel Vos</a> and his fans by <a href="https://www.youtube.com/channel/UCdWJIffMKQmfSZT5U4GV2ew">shottysteve</a>.
          Thanks to <a href="https://www.youtube.com/user/Nederlandsch">Deurklink</a> for the calculations, and to <a href="http://tomvanhees.be/rct2/index.php">Tom Vanhees</a> for making the first calculator.</BottomText>
          <BottomText>OpenRCT2 can be found <a href="https://openrct2.org/">here</a>. If you have any feedback or find any bugs, DM me on <a href="https://twitter.com/shottysteve">twitter</a>.</BottomText>
        </Main>
        </MainContainer>
      </div>
    );
  }

}
// var list = []
// var rows = document.querySelector("#t3_78maqk > div > div._3xX726aBn29LDbsDtzr_6E._1Ap4F5maDtT1E1YuCiaO0r.D3IL3FD0RFy_mkKLPwL4 > div > table").querySelectorAll("tr")
// for (var row of rows) {
//   var cells = row.querySelectorAll("td")
//   try{
//     list.push({
//       "Coaster type": cells[0].innerHTML,
//       "Highest drop height": cells[1].innerHTML,
//       "Number of drops": cells[2].innerHTML,
//       "Max speed": cells[3].innerHTML,
//       "Length": cells[4].innerHTML,
//       "Max Negative G": cells[5].innerHTML,
//       "Max Lateral G": cells[6].innerHTML,
//     })
//   } catch(e) {
//
//   }
//
// }
// console.log(list)


export default App;
